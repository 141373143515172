export const i18nSetRouteMeta = (data: any) => {
  if (!data) { return }

  const nuxtApp = useNuxtApp()
  const route = useRoute()
  const { locales } = nuxtApp.$i18n

  const i18nMetaObject = {}
  locales?.value?.forEach((i: any) => {
    i18nMetaObject[i?.code] = { slug: getLocaleSlug(i18nGetLocalizedData(baseStrapiGetAttributes(data))?.slugs, baseStrapiGetAttributes(data)?.slug, i?.code) }
  })

  if (i18nMetaObject) {
    const defaults = route.meta.nuxtI18n ? route.meta.nuxtI18n : {}
    route.meta.nuxtI18n = { ...defaults, ...i18nMetaObject }
  }

  if (baseStrapiGetAttributes(data)?.title) {
    route.meta.title = i18nGetLocalizedData(baseStrapiGetAttributes(data))?.titles?.current_locale || i18nGetLocalizedData(baseStrapiGetAttributes(data))?.title
  }
}

const getLocaleSlug = (slugs: array, slug: String, locale: string) => {
  if (!slugs && !slug) { return null }
  if (slugs instanceof Object) {
    return slugs[`i18n_slug_${locale}`]
  } else {
    return slug
  }
}
