<template>
  <div :class="$style.root">
    <BaseStrapiImageComponent
      :image="image"
      v-if="!isGif"
    />
    <BaseFigureComponent
      v-else
    >
      <img
        :src="getAbsoluteFileUrl(image?.url)"
        :alt="image?.alternativeText"
        :class="$style.svg"
        :style="{
          '--aspect-ratio': aspectRatio,
        }"
      />
      <template
        #caption
        v-if="caption && showCaption"
      >
        <div :class="$style.caption">
          {{ image }}
        </div>
      </template>
    </BaseFigureComponent>

    <div
      :class="$style.caption"
      v-if="caption && showCaption"
    >
      {{ caption }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  image: Object,
  showCaption: {
    type: Boolean,
    default: true,
  },
})

const caption = computed(() => {
  const caption = {
    i18n_caption_en: props?.image?.i18n_caption_en,
    i18n_caption_nl: props?.image?.i18n_caption_nl
  }
  const localeCaption = i18nGetLocalizedData(caption)
  return localeCaption?.current_locale || localeCaption?.i18n_caption_en
})

const isGif = props?.image?.ext === ('.gif')

const aspectRatio = computed(() => {
  return props?.image?.width / props?.image?.height
})

const getAbsoluteFileUrl = baseStrapiGetAbsoluteFileUrl
</script>

<style module>
.root {
  position: relative;
}

.svg {
  aspect-ratio: var(--aspect-ratio, auto);
}

.caption {
  composes: font-size-small from global;
  padding-top: var(--unit--spacer);
  text-align: center;
}
</style>
