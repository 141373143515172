export const pageSetHead = (data: object, ogImageUrl: string) => {
  // const alternates = baseStrapiGetMetaObject(data)?.localizations
  //   ? baseStrapiGetMetaObject(data).localizations?.map((localization) => {
  //     return {
  //       rel: 'alternate',
  //       hreflang: localization.locale,
  //       href: localization.url
  //     }
  //   })
  //   : []

  useHead({
    title: i18nGetLocalizedData(baseStrapiGetAttributes(data))?.titles?.current_locale || i18nGetLocalizedData(baseStrapiGetAttributes(data))?.title,
    // link: [
    //   ...alternates
    // ],
    htmlAttrs: [
      { lang: baseI18nGetActiveIso() },
      { dir: baseI18nGetActiveDir() }
    ]
  })

  useSeoMeta(
    basePageGetSeoMetaObject(baseStrapiGetMetaObject(data, ogImageUrl))
  )

  i18nSetRouteMeta(data)
}
