<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module lang="scss">
.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: calc(var(--unit--horizontal--double) * 2);
  grid-row-gap: var(--unit--vertical--tripple);

  width: 100%;

  @media (max-width: $breakpoint-s) {
    grid-column-gap: 0;
    grid-row-gap: var(--unit--vertical--tripple);

    grid-template-columns: 1fr;
  }
}

.root[data-break-early] {
  @media (max-width: $breakpoint-m) {
    grid-column-gap: 0;
    grid-row-gap: var(--unit--vertical--tripple);

    grid-template-columns: 1fr;
  }
}
</style>
